<template>
  <h1>Leckchecker</h1>
  <div id="card">
    <Checker />
  </div>
  <footer>
    <a href="https://wortfilter.de/" target="_blank">
      Bereitgestellt durch
      <img src="https://www.wortfilter.de/wp-content/uploads/2016/11/wf6.jpg" alt="Wortfilter.de">
    </a>
    <div id="disclaimer">
      <p>Wenn sie juristische Unterstützung benötigen, wenden sie sich bitte an: <a href="mailto:marktplatz-datenleck@wbs-law.de">marktplatz-datenleck@wbs-law.de</a></p>
      <p><b>Hinweis:</b> Sie übertragen ihre Eingabe Daten NICHT an unseren Server. In ihrem Internet Browser wird eine Prüfsumme errechnet und diese wird übertragen. Es ist nicht möglich diese Prüfsumme zu entschlüsseln.</p>
    </div>
    <div>
      <a href="https://wortfilter.de/impressum/" title="Impressum auf Wortfilter.de" target="_blank">Impressum</a>
      |
      <a href="https://wortfilter.de/datenschutz/" title="Datenschutzerklärung auf Wortfilter.de" target="_blank">Datenschutzerklärung</a>
    </div>
  </footer>
</template>

<script>
import Checker from './components/Checker.vue'

export default {
  name: 'App',
  components: {
    Checker
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#app #card {
  width: 100%;
  max-width: 20em;
  margin: 1rem auto;
  border: 1px solid #dedede;
  border-radius: .25em;
}

#app a {
  color: blue;
  text-decoration: none;
}

#app a img {
  margin-left: 1rem;
  height: 3rem;
  vertical-align: middle;
}

#app a:hover {
  text-decoration: underline;
}

#disclaimer {
  max-width: 480px;
  margin: 2em auto;
}
</style>
