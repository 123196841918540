<template>
<div>
  <form v-on:submit.prevent="submit">
    <label>
      Vorname:
      <input type="text" v-model="firstname">
    </label>
    <label>
      Nachname:
      <input type="text" v-model="lastname">
    </label>
    <label>
      Postleitzahl:
      <input type="text" v-model="postcode">
    </label>
    <button type="submit">Prüfen</button>
    <div v-if="this.result">
      <div v-if="this.result.platforms" class="result result-f">
        Ihre Daten wurden im Leck gefunden!<br>
        <ul>
          <li v-for="platform in this.result.platforms" v-bind:key="platform">
            {{platform}}
          </li>
        </ul>
      </div>
      <div v-else class="result result-nf">
        Ihre Daten wurden nicht im Leck gefunden.
      </div>
    </div>
  </form>
</div>
</template>

<script>
import md5 from 'md5';
import axios from "axios";

export default {
  name: "Checker",
  data() {
    return {
      firstname: "",
      lastname: "",
      postcode: "",
      result: null,
    }
  },
  methods: {
    async submit() {
      this.result = null;
      let h = this.firstname.trim().toLowerCase();
      h += this.lastname.trim().toLowerCase();
      h += this.postcode.trim().toLowerCase();
      h = md5(h);
      let result = await axios.get("https://www.leckchecker.wortfilter.de/check.php?h=" + h);
      if(result.data.platforms.length > 0) {
        this.result = result.data;
        return false;
      }
      this.result = {platforms: null};
      return false;
    }
  }
}
</script>

<style scoped>
  form {
    padding: .25em 1.25em;
  }

  label {
    display: block;
    text-align: left;
    margin: .75em 0;
  }

  input {
    display: block;
    width: 100%;
    width: calc(100% - .5em);
    border: 1px solid #ababab;
    border-radius: .25em;
    width: calc(100% - .5em - 2px);
    margin: .25em 0;
    padding: .4em .25em;
  }

  button {
    width: 100%;
    display: block;
    margin: .75em 0;
    padding: .4em .25em;
    color: #ffffff;
    background-color: #1818ea;
    border: none;
    border-radius: .5em;
    cursor: pointer;
    font-size: 1rem;
  }

  button:hover {
    background-color: #1515b2;
    box-shadow: .125rem .125rem .5rem #9d9d9d;
  }

  .result {
    text-align: center;
    display: block;
    border: 1px solid #ffffff;
    border-radius: .5em;
    padding: .5em;
    margin: .5em 0;
    font-size: 1em;
    line-height: 1.125em;
  }

  .result-nf {
      border-color: #c3e6cb;
      background-color: #d4edda;
      color: #155724;
  }

  .result-f {
    border-color: #f5c6cb;
    color: #721c24;
    background-color: #f8d7da;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: .25em 0;
    font-size: .75em;
    line-height: 1.125em;
    text-align: center;
  }

  li {
    display: inline;
  }

  li::before {
    content: ' · ';
    display: inline;
  }

  li:nth-of-type(1)::before {
    content: '';
  }
</style>